:root {
  --color-primary: #000;
  --color-secondary: #000;
  --color-neutral: gray;
  --border-radius: 1rem;
  --palette-height-l: 200px;
  --palette-height-m: 100px;
  --typeface-heading: "Quicksand", sans-serif;
  --typeface-body: "Quicksand", sans-serif;
  --scale-module: 1.125;
  --font-size-body: 1rem;
  --font-size-small: calc(var(--font-size-body) / var(--scale-module));
  --font-size-h6: calc(var(--font-size-body) * var(--scale-module));
  --font-size-h5: calc(var(--font-size-h6) * var(--scale-module));
  --font-size-h4: calc(var(--font-size-h5) * var(--scale-module));
  --font-size-h3: calc(var(--font-size-h4) * var(--scale-module));
  --font-size-h2: calc(var(--font-size-h3) * var(--scale-module));
  --font-size-h1: calc(var(--font-size-h2) * var(--scale-module));
  --font-weight-key: 300;
  --font-weight-body-strong: 400;
  --font-weight-h6: 300;
  --font-weight-h5: 400;
  --font-weight-h4: 400;
  --font-weight-h3: 400;
  --font-weight-h2: 500;
  --font-weight-h1: 700;
  font-size: 16px;
}

.main-theme-bright {
  --font-color: #000;
  --bg-color: #f0f0f0;
  --layout-border: #00000026;
  --layout-skelekton: #00000026;
  --font-color-clickable: #007fda;
  --utility-color-error: #d73c3c;
  --input-border: #00000054;
}

.main-theme-bright .exclude-from-color-theme {
  color: initial;
  background: initial;
}

.main-theme-dark {
  --font-color: #fff;
  --bg-color: #1e1e1e;
  --layout-border: #ffffff26;
  --layout-skelekton: #ffffff26;
  --font-color-clickable: #52d6fc;
  --utility-color-error: #ff6d6d;
  --input-border: #ffffff80;
}

.main-theme-dark .exclude-from-color-theme {
  color: initial;
  background: initial;
}

body {
  color: var(--font-color);
  background: var(--bg-color);
  width: auto;
  font-family: Arial, sans-serif;
  font-weight: regular;
  flex-direction: column;
  margin: 0;
  padding: 0;
  font-size: 16px;
  display: flex;
}

.header {
  border-bottom: 1px solid var(--layout-border);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  margin-bottom: 3rem;
  padding: 1rem;
  font-weight: 200;
  display: flex;
}

.header-title {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.header-logo {
  width: 4rem;
  min-height: 1.5rem;
  text-align: baseline;
  font-size: var(--font-size-h5);
  font-family: var(--typeface-heading);
  font-weight: var(--font-weight-h1);
  justify-content: start;
  align-items: center;
  gap: 0;
  display: inline-block;
}

.header-logo svg {
  max-width: 100%;
  height: auto;
  display: block;
}

a {
  color: var(--font-color-clickable);
}

.body-text {
  font-family: var(--typeface-body);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-key);
  line-height: 1.5;
}

.body-text-strong {
  font-family: var(--typeface-body);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-body-strong);
  line-height: 1.5;
}

.body-text-small {
  font-family: var(--typeface-body);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-key);
  line-height: 1.5;
}

.body-text-small-strong {
  font-family: var(--typeface-body);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-body-strong);
}

.heading-06 {
  font-family: var(--typeface-heading);
  font-size: var(--font-size-h6);
  font-weight: var(--font-weight-h6);
}

.heading-05 {
  font-family: var(--typeface-heading);
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-h5);
}

.heading-04 {
  font-family: var(--typeface-heading);
  font-size: var(--font-size-h4);
  font-weight: var(--font-weight-h4);
}

.heading-03 {
  font-family: var(--typeface-heading);
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-h3);
}

.heading-02 {
  font-family: var(--typeface-heading);
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-h2);
}

.heading-01 {
  font-family: var(--typeface-heading);
  font-size: var(--font-size-h1);
  font-weight: var(--font-weight-h1);
}

.text-error {
  font-family: var(--typeface-body);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-h5);
  color: #fff;
  background: var(--utility-color-error);
  text-align: center;
  border-radius: .15rem;
  padding: .25rem .5rem;
}

.wrapper-primary-color {
  color: inherit;
  grid-template-rows: auto 2rem;
  grid-template-columns: auto 2rem;
  display: grid;
}

#color-input {
  color: inherit;
  flex-direction: row-reverse;
  grid-area: 1 / 1 / span 2 / span 2;
  width: 100%;
  display: flex;
}

.input-field-emphasized {
  font-family: var(--typeface-heading);
  font-optical-sizing: auto;
  font-weight: var(--font-weight-h1);
  text-transform: uppercase;
  background-color: var(--color-primary);
  z-index: 0;
  border: none;
  flex: 1;
  grid-area: 1 / 1 / span 2 / span 2;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 0 0 1.5rem;
  font-size: clamp(2rem, 10vmin, 12rem);
}

#main-input-label {
  width: 60%;
}

.input-field-emphasize:focus {
  background-color: var(--color-primary);
  outline: 1rem solid var(--layout-border);
  outline-offset: -1rem;
  border: none;
}

.input-field-emphasize::-webkit-input-placeholder {
  color: rgb(from var(--font-color) r g b / .25);
  background: none;
}

.wrapper-color-picker {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

.color-thumb {
  background-color: var(--color-primary);
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  padding-bottom: .25rem;
}

#ctrl-hues {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.wrapper-seg-ctrl {
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 37%;
  margin: 0;
  padding: 0;
  display: flex;
}

.seg-ctrl {
  flex-direction: row;
  align-items: flex-start;
  gap: .5rem;
  display: inline-flex;
}

.seg-ctrl-tab {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--font-color);
  cursor: pointer;
  padding-bottom: .25rem;
}

.utility-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--font-color);
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="color"] {
  -webkit-appearance: none;
  border: 2px solid var(--input-border);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
}

input[type="range"] {
  appearance: none;
  cursor: pointer;
  background: none;
  width: 100%;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  background-color: var(--font-color);
  border-radius: 50%;
  width: .66rem;
  height: .66rem;
  margin-top: -.27rem;
}

input[type="range"]::-moz-slider-thumb {
  appearance: none;
  background-color: var(--font-color);
  border-radius: 50%;
  width: .66rem;
  height: .66rem;
  margin-top: -.27rem;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: var(--layout-border);
  border-radius: .25rem;
  height: .15rem;
}

input[type="range"]::-moz-range-track {
  background: var(--layout-border);
  height: .33rem;
}

.color-picker-menu {
  display: none;
  position: absolute;
}

#color-input:focus + .color-picker-menu {
  display: block;
}

.palette {
  border-radius: var(--border-radius);
  background: var(--layout-skelekton);
  flex-wrap: wrap;
  justify-content: start;
  width: 100%;
  list-style: none;
  display: flex;
  overflow: hidden;
}

.palette-brand {
  flex-flow: wrap;
  width: 100%;
  height: 100%;
  min-height: 25vh;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.palette-brand li:first-child {
  background-color: var(--color-primary);
  flex: 1;
  margin: 0;
  padding: 0;
}

.palette-brand li:nth-child(2) {
  background-color: var(--color-secondary-rgb);
  flex: .6;
}

.palette-label {
  font-size: var(--font-size-h6);
  color: var(--font-color);
  grid-column: 1 / span 1;
  gap: .5rem;
  margin: 0;
  display: flex;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + label {
  color: #000;
  border-bottom: 2px solid var(--font-color);
}

.color-adjust-input {
  width: 100%;
  accent-color: var(--font-color);
  gap: .75rem;
  margin: auto;
  display: flex;
}

.color-adjust-input label {
  font-size: var(--font-size-small);
  align-self: bottom;
  grid-row: 1;
}

.color-adjust-input input {
  outline-offset: .25rem;
  outline-width: thin;
  grid-row: 2;
}

.color-adjust-input .slider-input {
  grid-column: 1 / 3;
}

.color-adjust-input .number-input {
  color: var(--font-color);
  grid-column: 4 / 5;
}

.number-input {
  text-align: center;
  border: 1px solid var(--layout-border);
  background: none;
  border-radius: .25rem;
}

.number-input:focus {
  background: var(--layout-border);
  border: 1px solid var(--layout-border);
  border-radius: .25rem;
}

.sliders-column {
  flex-direction: column;
  flex: .66;
  align-self: flex-end;
  gap: 1rem;
  width: 37%;
  height: auto;
  margin-top: 1rem;
  display: flex;
}

.label-and-status {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
  width: 100%;
  display: flex;
}

#contrast-status {
  gap: .25rem;
  width: fit-content;
  height: 1rem;
  display: flex;
}

#contrast-status span {
  border-radius: .25rem;
  grid-template-columns: 1rem auto;
  place-items: center;
  width: fit-content;
  height: 1rem;
  margin: 0;
  padding-left: 1rem;
  display: grid;
}

#contrast-status > .success {
  opacity: 1;
  color: currentColor;
  font-weight: var(--font-weight-h4);
}

#contrast-status > .fail {
  opacity: .33;
  color: currentColor;
  font-weight: var(--font-weight-h4);
}

.icon-status {
  place-items: center;
  width: 1rem;
  height: 1rem;
  margin: 0;
  padding: 0;
  display: grid;
}

.icon-status svg {
  width: inherit;
  height: inherit;
  margin: 0;
}

.copy-icon, .check-icon {
  pointer-events: none;
  width: 2rem;
  height: 2rem;
  color: inherit;
  opacity: .33;
  transition: opacity .2s;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.copy-icon .copy-path, .copy-icon .check-path, .check-icon .copy-path, .check-icon .check-path {
  fill: currentColor;
}

#main-input-copy-to-cb {
  color: inherit;
}

#main-input-copy-to-cb svg {
  fill: currentColor;
}

.palette-heading {
  flex-direction: row;
  justify-content: space-between;
  align-items: last baseline;
  display: flex;
}

.button-copy {
  z-index: 10;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  color: inherit;
  grid-area: 2 / 2 / span 1 / span 1;
}

.button-copy:hover {
  opacity: .5;
  color: inherit;
}

.label-button-container {
  grid-template-columns: auto 1fr auto;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: grid;
}

.neutral-chroma-toggle {
  width: auto;
  color: var(--font-color);
  font-family: var(--typeface-body);
  font-size: var(--font-size-small);
  grid-column: 2 / span 1;
  justify-self: start;
  margin-left: .5rem;
  margin-right: auto;
  font-weight: 200;
}

.copy-json-button svg {
  width: 1rem;
  height: 1rem;
}

.copy-json-button:hover {
  color: var(--font-color);
  opacity: 1;
  background: none;
}

.copy-json-button:focus {
  color: var(--font-color);
  background: var(--layout-border);
  opacity: 1;
}

.copy-json-button:active {
  color: var(--font-color);
  background: rgb(from var(--font-color) r g b / .25);
}

.section {
  border-radius: 1rem;
  flex-direction: column;
  gap: 1rem;
  width: 90%;
  height: auto;
  margin: 2rem auto auto;
  display: flex;
  position: relative;
}

.palettes-section {
  border-top: solid 1px var(--layout-border);
  flex-direction: column;
  gap: .5rem;
  width: 90%;
  height: auto;
  margin: 2rem auto auto;
  display: flex;
  position: relative;
}

.color-swatch-container {
  border-radius: 1rem;
  flex-flow: wrap;
  justify-content: center;
  gap: 0;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  overflow: hidden;
}

.swatch__MarkerContainer {
  grid-area: 1 / 3 / span 1 / span 1;
  justify-self: end;
  width: fit-content;
  display: flex;
  position: relative;
  top: .5rem;
}

.swatch__Marker {
  color: var(--bg-color);
  background-color: #00000026;
  border-radius: .25rem 0 0 .25rem;
  justify-self: end;
  padding: 2px 4px;
  font-size: .8em;
  font-weight: bold;
  top: 5px;
}

.swatch-marked {
  border-radius: 0 0 1rem;
  overflow: hidden;
}

.contrast-ratio {
  background-color: #0000;
  border-radius: 3px;
  padding: 2px 4px;
  font-size: .75rem;
}

.color-swatch {
  font-family: var(--typeface-body);
  font-size: var(--font-size-small);
  cursor: pointer;
  outline: none;
  flex: 1;
  grid-template-rows: 2rem auto 2rem;
  min-height: 6rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: grid;
  position: relative;
}

.scale-swatch-container {
  min-height: 12rem;
  height: var(--palette-height-l);
}

.accent-swatch-container {
  min-height: 6rem;
  height: var(--palette-height-m);
  justify-content: flex-end;
  display: flex;
}

.hex-value-container {
  font-family: var(--typeface-body);
  flex-direction: column;
  grid-row: 3 / span 1;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  padding-left: .5rem;
  display: flex;
}

.hex-value {
  flex-grow: 0;
  font-size: .875rem;
  font-weight: 500;
}

.hex-value-container svg {
  flex-shrink: 0;
}

#secondary-color {
  cursor: pointer;
  grid-template-rows: 2rem auto 2rem;
  grid-template-columns: 2rem auto 2rem;
  place-items: center;
  padding: .5rem;
  display: grid;
  position: relative;
}

#secondary-color .hex-value {
  flex-grow: 1;
}

#secondary-color svg {
  width: 4rem;
  height: 4rem;
}

.section-text {
  background: #00f;
  position: relative;
}

.copied-message {
  color: #fff;
  background-color: #000000b3;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scale-row, .harmonic-row {
  margin-bottom: 20px;
}

.switch {
  width: 1.85rem;
  height: 1.5rem;
  display: inline-block;
  position: relative;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  cursor: pointer;
  transform: 1;
  background-color: #0000;
  transition: all .4s;
  position: absolute;
  inset: 0 -1rem 0 0;
}

.slider:before {
  content: "";
  opacity: .66;
  background-color: #0000;
  border: 1px solid;
  width: .95rem;
  height: .95rem;
  transition: all .2s;
  position: absolute;
  bottom: .15rem;
  left: .15rem;
}

input:checked + .slider {
  background-color: #0000;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0000;
}

input:checked + .slider:before {
  opacity: 1;
  background: currentColor;
  transform: translateX(1.33rem);
}

.slider.round {
  border: 1px solid;
  border-radius: 2rem;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-comp {
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.toggle-comp input:focus + .slider {
  outline-offset: 2px;
  outline: 2px solid;
}

.toggle-comp input:focus:not(:focus-visible) + .slider {
  outline: none;
}

.toggle-comp input:focus-visible + .slider {
  outline-offset: 2px;
  outline: 2px solid;
}

.text_section {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: .5rem;
  display: flex;
}

.text_section * {
  max-width: 50rem;
  margin: 0;
}

.text_section .heading-05 {
  margin-top: 1rem;
}

.text_section .heading-04 {
  margin-top: .5rem;
}

.footer {
  text-align: center;
  border-top: solid 1px var(--layout-border);
  width: 100%;
  height: 8rem;
  color: var(--font-color);
  place-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 2;
  display: grid;
  position: relative;
}

.footer p {
  margin: auto;
  padding: 2rem;
  position: relative;
}

#generate-shareable-url-button {
  background: rgb(from var(--font-color) r g b / 10%);
  align-self: flex-end;
  width: fit-content;
  margin-top: 1rem;
  padding: .75rem;
  font-weight: 500;
  display: flex;
}

@media only screen and (width <= 900px) {
  .root {
    --palette-height-l: auto;
  }

  .header {
    border-bottom: 1px solid var(--layout-border);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: auto;
    margin-bottom: 0;
    padding: 1rem;
    font-weight: 200;
    display: flex;
  }

  .palettes-section {
    flex-direction: column;
    width: 90%;
    height: auto;
    margin: 2rem auto auto;
    display: flex;
  }

  .color-swatch-container {
    flex-direction: column-reverse;
    height: auto;
    max-height: none;
  }

  .scale-swatch-container {
    min-height: none;
    height: auto;
    max-height: none;
  }

  .color-swatch {
    width: 100%;
    height: 3rem;
    min-height: 3rem;
  }

  .hex-value-container {
    justify-content: flex-end;
    margin: auto;
    display: flex;
    bottom: auto;
    left: auto;
  }

  .palette-brand {
    height: 50vmin;
    display: flex;
  }

  .palette-brand li:first-child {
    background-color: var(--color-primary);
    margin: 0;
    padding: 0;
  }

  .palette-brand li:nth-child(2) {
    background-color: var(--color-secondary-rgb);
    flex: .8;
  }

  #main-input-label {
    width: 100%;
  }

  .sliders-column {
    flex-direction: column;
    flex: 1;
    align-self: flex-end;
    gap: 1rem;
    width: 100%;
    height: auto;
    margin: 1rem auto auto;
    display: flex;
  }

  .input-field-emphasized {
    text-align: center;
    margin: auto;
    padding-left: 0;
    font-size: 10vw;
  }

  #ctrl-hues {
    align-items: flex-start;
    gap: .5rem;
  }

  .wrapper-color-picker {
    flex-direction: column-reverse;
    grid-area: 1 / 1 / 3 / 2;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin: 0;
    padding: 0;
    display: flex;
  }

  .color-thumb {
    background-color: var(--color-primary);
    border-radius: 50%;
    align-self: flex-start;
    margin-top: 0;
    margin-bottom: .25rem;
    width: 1.8rem !important;
    height: 1.8rem !important;
  }

  input[type="color"] {
    width: 1.8rem;
    height: 1.8rem;
    padding-bottom: 0;
  }

  .wrapper-seg-ctrl {
    flex-direction: column;
    grid-area: 1 / 2 / 3 / 3;
    align-items: flex-end;
    gap: 1rem;
    margin: 0;
    padding: 0;
  }

  .seg-ctrl {
    flex-direction: row;
    align-items: flex-start;
    height: 100%;
    display: inline-flex;
  }

  .seg-ctrl-tab {
    width: 2rem;
    height: 2rem;
    color: var(--font-color);
    cursor: pointer;
    padding-bottom: .25rem;
  }

  input[type="range"]::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
  }

  input[type="range"]::-moz-slider-thumb {
    width: 1rem;
    height: 1rem;
  }

  .slider {
    transform: scale(1);
  }
}

.swatch__MarkerContainer {
  width: fit-content;
  height: fit-content;
  display: block;
  position: relative;
  top: .5rem;
  right: 0;
}

.copy-json-button {
  color: var(--font-color);
  font-family: var(--typeface-body);
  font-size: var(--font-size-small);
  opacity: .85;
  cursor: pointer;
  border: none;
  border: 1px solid var(--font-color);
  background: none;
  border-radius: .25rem;
  flex-direction: row;
  grid-column: 3 / span 1;
  align-items: center;
  gap: .25rem;
  width: auto;
  margin-left: .5rem;
  padding: .5rem;
  font-weight: 200;
  display: flex;
}

@media only screen and (width >= 1920px) {
  :root {
    font-size: 24px;
  }

  .slider:before {
    content: "";
    width: .95rem;
    height: .95rem;
    position: absolute;
    bottom: .22rem;
    left: .25rem;
  }
}

.indication-scale, .color-swatch-container.indication-scale {
  height: var(--palette-height-m);
  min-height: var(--palette-height-m);
  flex-direction: row;
  gap: 0;
  margin-bottom: 1rem;
  display: flex;
}

.indication-scale .color-swatch, .color-swatch-container.indication-scale .color-swatch {
  height: 100%;
  min-height: unset;
  cursor: pointer;
  background-color: #0000;
  border: none;
  outline: none;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: .5rem;
  display: flex;
  position: relative;
}

.indication-scale .color-swatch .copy-icon, .indication-scale .color-swatch .check-icon {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.indication-scale .color-swatch:hover .copy-icon, .indication-scale .color-swatch:focus-visible .copy-icon {
  display: block;
}

.indication-scale .hex-value-container {
  text-align: left;
  opacity: 1;
  width: 100%;
  padding-left: 1rem;
  font-size: .8rem;
  position: absolute;
  bottom: .5rem;
}

@media only screen and (width <= 768px) {
  .indication-scale, .color-swatch-container.indication-scale {
    height: calc(var(--palette-height-m) * .7);
    min-height: calc(var(--palette-height-m) * .7);
  }

  .indication-scale .hex-value-container {
    font-size: .7rem;
    bottom: .25rem;
  }
}

.utilities-container {
  border-top: 1px solid var(--layout-border);
  margin-top: 2rem;
  padding-top: 1rem;
}

.utilities-container > .label-button-container {
  margin-bottom: 1.5rem;
}

.indication-rows-grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  width: 100%;
  display: grid;
}

.indication-row-container {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.indication-row-container .label-button-container {
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
  display: flex;
}

.indication-row-container .row-label {
  font-size: var(--font-size-small);
}

.indication-row-container .color-swatch-container {
  margin-bottom: 0;
}

@media only screen and (width <= 768px) {
  .indication-rows-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .utilities-container > .label-button-container {
    margin-bottom: 1rem;
  }
}

.utilities-container .label-button-container, .indication-row-container .label-button-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
  display: flex;
}

.utilities-container .row-label, .indication-row-container .row-label {
  font-family: var(--typeface-body);
  font-size: var(--font-size-small);
  color: var(--font-color);
  font-weight: 400;
}

.utilities-container .copy-json-button, .indication-row-container .copy-json-button {
  color: var(--font-color);
  font-family: var(--typeface-body);
  font-size: var(--font-size-small);
  opacity: .85;
  cursor: pointer;
  border: none;
  border: 1px solid var(--font-color);
  background: none;
  border-radius: .25rem;
  flex-direction: row;
  align-items: center;
  gap: .25rem;
  width: auto;
  margin-left: .5rem;
  padding: .5rem;
  font-weight: 200;
  display: flex;
}
/*# sourceMappingURL=index.d2f2b5e4.css.map */
